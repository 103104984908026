<template>
  <BModal :modalActive="value">
    <div class="flex items-center justify-between px-4 md:p-5 rounded-t">
      <h3 class="text-xl font-semibold text-brown">
        Calculadora
      </h3>
      <button
        type="button"
        class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
        @click="$emit('update:value', false)"
      >
        <svg
          class="w-3 h-3"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 14 14"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
          />
        </svg>
        <span class="sr-only">Close modal</span>
      </button>
    </div>
    <!-- Modal body -->
    <div class="p-4">
      <h1 class="text-3xl font-semibold text-brown text-center">Retorno annual</h1>
      <h1 class="text-4xl font-semibold text-green text-center">
        R$ {{returnOnInv}}
      </h1>

      <div class="font-bold mt-5 text-brown">
        Economia
      </div>
      <SummaryLine
        name="Economia por kg"
        leadingUnit="R$"
        :value="savedPerKg"
      />
      <SummaryLine
        name="Economia annual total"
        leadingUnit="R$"
        :value="savedPerYearFormatted"
      />

      <div class="font-bold mt-5 text-brown">
        Retorno aditional
      </div>
      <SummaryLine
        name="Venda de café torrado"
        leadingUnit="R$"
        :value="retailEarnedPerYearFormatted"
      />

      <SummaryLine
        name="Redução nas emissões de CO2 /ano"
        unit="kg"
        :value="CO2Saved"
      />

      <hr class="border-gray-200 my-5">

      <SliderInput
        v-model:value="beansPerWeek"
        name="Quantidade de grãos/semana"
        unit="kg"
        :min="0"
        :max="30"
      />
      <!-- <SliderInput
        v-model:value="precoRoastedPerKg"
        name="Preço do cafe especial torrado /kg"
        leadingUnit="R$ "
        :min="60"
        :max="110"
      />
      <SliderInput
        v-model:value="precoGreenPerKg"
        name="Preço do cafe especial cru /kg"
        leadingUnit="R$ "
        :min="30"
        :max="120"
      /> -->

      <hr class="border-gray-200 my-5">
      <div class="font-bold text-brown">
        Retorno adicional com venda de cafe
      </div>
      <div class="font-light text-zinc-400">
        Lucra ainda mais vendendo seu proprio cafe torrado
      </div>
      <SliderInput
        v-model:value="pacoteSoldPerWeek"
        name="Quantidade de pacote(250gr) vendida/semana"
        unit=""
        :min="0"
        :max="40"
      />
      <SliderInput
        v-model:value="retailPrice"
        name="Preço de venda"
        leadingUnit="R$ "
        :min="10"
        :max="50"
      />
    </div>
    <!-- Modal footer -->
    <div class="flex items-center p-4 md:p-5 rounded-b justify-end">
      <button
        type="button"
        class="text-white bg-green focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
        @click="$emit('update:value', false)"
      >Fechar</button>
    </div>
  </BModal>
</template>

<script>
import SliderInput from './SliderInput.vue';
import SummaryLine from './SummaryLine.vue';
import BModal from './BModal.vue';

export default {
  name: 'ROIModal',
  components: { SliderInput, SummaryLine, BModal },
  props: {
    value: Boolean,
  },
  data() {
    return {
      beansPerWeek: 5,
      precoRoastedPerKg: 80,
      precoGreenPerKg: 40,
      pacoteSoldPerWeek: 15,
      retailPrice: 25,
      gasCO2PerKg: 2.75,
      elecCO2PerKg: 0.35,
    };
  },
  watch: {
    value(val) {
      if (val) {
        const scrollY = window.scrollY;
        const body = document.body;
        body.style.position = 'fixed';
        body.style.top = `-${scrollY}px`;
      } else {
        const body = document.body;
        const scrollY = body.style.top;
        body.style.position = '';
        body.style.top = '';
        window.scrollTo(0, parseInt(scrollY || '0') * -1);
      }
    },
  },
  computed: {
    savedPerKg() {
      return this.precoRoastedPerKg - this.precoGreenPerKg;
    },
    savedPerYear() {
      return this.savedPerKg * this.beansPerWeek * 52;
    },
    savedPerYearFormatted() {
      return this.savedPerYear.toLocaleString();
    },
    retailEarnedPerYear() {
      return (
        (this.retailPrice - this.precoGreenPerKg / 4) *
        this.pacoteSoldPerWeek *
        52
      );
    },
    retailEarnedPerYearFormatted() {
      return this.retailEarnedPerYear.toLocaleString();
    },
    returnOnInv() {
      return (this.savedPerYear + this.retailEarnedPerYear).toLocaleString();
    },
    CO2Saved() {
      return (
        (this.gasCO2PerKg - this.elecCO2PerKg) *
        (this.beansPerWeek * 52)
      ).toLocaleString();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
input[type='range'] {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
  width: 100%;
}

/* Removes default focus */
input[type='range']:focus {
  outline: none;
}

/******** Chrome, Safari, Opera and Edge Chromium styles ********/
/* slider track */
input[type='range']::-webkit-slider-runnable-track {
  background-color: #e6e6e6;
  border-radius: 0.5rem;
  height: 0.5rem;
}

/* slider thumb */
input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  margin-top: -4px; /* Centers thumb on the track */
  background-color: #808080;
  border-radius: 0.5rem;
  height: 1rem;
  width: 1rem;
}

input[type='range']:focus::-webkit-slider-thumb {
  outline: 3px solid #808080;
  outline-offset: 0.125rem;
}
</style>
