import { createApp } from 'vue'
import './index.css'
import { gsap } from "gsap";
    
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollSmoother } from 'gsap/ScrollSmoother';

// Components
import App from './App.vue'


gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

createApp(App).mount('#app')

