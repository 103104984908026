<template>
  <div>
    <div class="main-header fixed top-0 left-0 right-0 z-20 flex justify-between p-4 items-center bg-white">
      <img
        alt="Beyond Roast logo"
        src="../assets/beyond-roast-logo.svg"
      >
      <a
        class="bg-green  text-white py-1 px-3 rounded-full"
        href="https://tawk.to/chat/66e33bd250c10f7a00a8f1af/1i7jq3br6"
      >
        Entrar em contato
      </a>
    </div>

    <div class="bg-light-gray">
      <div class="flex flex-col justify-center pt-[120px] absolute w-full">
        <h1 class="box catch-line text-4xl font-semibold text-brown text-center mx-3 mb-5 z-10">
          A Torra de Café,<br> Redefinida.
        </h1>
      </div>
      <div class="h-[160px]"></div>
      <div class="pin-roaster roaster-hero">
        <canvas
          id="image-sequence"
          class="box z-10 w-full mx-auto"
        />
        <!-- <img
          alt="Roaster"
          src="../assets/render5.png"
          class="box mx-auto z-10 h-[550px]"
        > -->

      </div>
      <div>
        <!-- <h1 class="box text-2xl font-semibold text-brown text-center py-1 px-4"></h1> -->
        <section class="mt-4">
          <p class="box">
            <b>
              Elegância na Simplicidade.
            </b>
            Desfrute da beleza da torra de café. Com uma câmara transparente, seus clientes podem acompanhar a transformação dos grãos verdes em café perfeitamente torrado.
          </p>
        </section>

        <section class="px-0">
          <h1 class="box text-4xl font-semibold text-brown px-8">
            Controle ao seu alcance.
          </h1>
          <img
            alt="Roaster"
            src="../assets/touch-screen.png"
            class="product-image box mx-auto z-10"
          >
          <p class="box px-8">
            <b>
              O painel touch Integrado.
            </b>
            fornece atualizações em tempo real e
            <b>
              controle total
            </b>
            do processo de torra.
          </p>
        </section>

        <section class="relative px-0">
          <h1 class="box text-4xl font-semibold text-brown px-8">
            Precisão e conveniência.
          </h1>

          <div class="relative flex justify-start  w-[65%]">
            <img
              alt="Roaster"
              src="../assets/beancoscreen.png"
              class="product-image h-[230px] w-full object-cover object-right box z-10"
            >
            <img
              alt="Roaster"
              src="../assets/coffee-bag.png"
              class="coffee-bag box mx-auto z-10 w-[140px] absolute bottom-[-5px] right-[-120px]"
            >
          </div>

          <p class="box bean-connect-description z-10 px-8">
            Basta escanear o pacote de grãos de café, e o
            <b>
              Bean Connect ajusta automaticamente
            </b>
            os melhores parâmetros de torra para você.
          </p>
          <img
            alt="Bean connect"
            src="../assets/bean-connect-bg.svg"
            class="absolute bottom-[-10%] left-[-30%] z-0"
          >
        </section>

        <section class="">
          <h1 class="box text-4xl font-semibold text-brown">
            Economize Dinheiro e Reduza Emissões.
          </h1>

          <img
            alt="Roaster"
            src="../assets/beyond-roast-coffee-shop.png"
            class="box mx-auto rounded-xl my-7 z-10 h-52 w-full object-cover"
          >

          <p class="box">
            <b>
              Ao torrar seu próprio café,
            </b>
            você economiza significativamente no custo dos grãos. O café verde é mais barato que o café já torrado, proporcionando
            <b>
              uma redução direta nos custos operacionais.
            </b>
          </p>

          <div class="flex justify-center box mt-3">
            <button
              class="bg-green  text-white py-1 px-3 rounded-full"
              @click="openModal"
            >
              Calcular retorno
            </button>
          </div>

          <img
            alt="Roaster"
            src="../assets/coffee-field.jpg"
            class="box mx-auto rounded-xl my-7 z-10 h-52 w-full object-cover"
          >

          <p class="box">
            <b>
              Nosso torrador elétrico é mais limpo,
            </b>
            e eficiente que os modelos a gás utilizados no mercado.
            <b>
              Reduz as emissões de CO2
            </b>
            e oferece controle preciso da temperatura,
            <b>
              garantindo torras consistentes e de alta qualidade.
            </b>
            Torrar no seu local diminui ainda mais as emissões.

          </p>
        </section>

        <section class="bg-white py-7 box rounded-xl">
          <h1 class="text-4xl font-semibold text-brown">
            Revolucione a Experiência do Seu Café.
          </h1>
          <div class="my-4">
            <KeyFeature
              title="Excelência Automatizada"
              text="Torras consistentes e de alta qualidade com mínimo esforço."
            />
            <KeyFeature
              title="Visuais Envolventes"
              text="Transforme a torra em uma experiência fascinante para seus clientes."
            />
            <KeyFeature
              title="Controle Intuitivo"
              text="Interação perfeita com uma interface touch elegante."
            />
            <KeyFeature
              title="Escaneamento Inteligente"
              text="Torras perfeitas sempre, com a tecnologia Bean Connect."
            />
            <KeyFeature
              title="Economia e Sustentabilidade"
              text="Reduza custos com grãos verdes e diminua emissões de CO2 com nosso torrador elétrico eficiente."
            />
          </div>
          <div class="flex justify-center mt-3">
            <a
              class="bg-green  text-white py-1 px-3 rounded-full"
              href="https://tawk.to/chat/66e33bd250c10f7a00a8f1af/1i7jq3br6"
            >
              Entrar em contato
            </a>
          </div>
        </section>

      </div>
    </div>
    <footer class="bg-white p-7">
      <img
        alt="Beyond Roast logo"
        src="../assets/beyond-roast-logo.svg"
      >
    </footer>

    <ROIModal v-model:value="isModalOpened" />
  </div>
</template>

<script>
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollSmoother } from 'gsap/ScrollSmoother';
import ROIModal from './ROIModal.vue';
import KeyFeature from './KeyFeature.vue';
export default {
  name: 'HelloWorld',
  components: { ROIModal, KeyFeature },
  props: {
    msg: String,
  },
  data() {
    return {
      isModalOpened: false,
    };
  },
  mounted() {
    gsap.registerPlugin(ScrollTrigger, ScrollSmoother);
    // ScrollTrigger.normalizeScroll(true);

    // ScrollSmoother.create({
    //   smooth: 1, // how long (in seconds) it takes to "catch up" to the native scroll position
    //   effects: true, // looks for data-speed and data-lag attributes on elements
    //   smoothTouch: 0.1, // much shorter smoothing time on touch devices (default is NO smoothing on touch devices)
    // });

    ScrollTrigger.create({
      trigger: '.pin-roaster',
      pin: '.pin-roaster',
      start: 'center center',
      end: '+=700',
      pinSpacing: true,
    });

    this.setSequence();

    gsap.to('.roaster-hero', {
      backgroundPositionX: '100%',
      ease: 'none',
      scrollTrigger: {
        trigger: '.pin-roaster',
        start: 'center center',
        end: '+=2000',
        scrub: true,
      },
    });
    gsap.to('.coffee-bag', {
      bottom: '20px',
      ease: 'none',
      scrollTrigger: {
        trigger: '.coffee-bag',
        scrub: true,
      },
    });
    const boxes = gsap.utils.toArray('.box');
    boxes.forEach((box) => {
      const anim = gsap.fromTo(
        box,
        { autoAlpha: 0, y: 50 },
        { duration: 1, autoAlpha: 1, y: 0 }
      );
      ScrollTrigger.create({
        trigger: box,
        animation: anim,
        toggleActions: 'play none none none',
        once: true,
      });
    });
  },
  methods: {
    openModal() {
      this.isModalOpened = true;
    },
    setSequence() {
      const canvas = document.getElementById('image-sequence');
      const context = canvas.getContext('2d');

      canvas.width = 1440;
      canvas.height = 2000;
      const startFrame = 0;
      const frameCount = 117 - startFrame;

      const currentFrame = (index) =>
        `/roastsequence/beyond-roast-${(
          startFrame +
          index +
          1
        ).toString()}.png`;

      const images = [];
      const airpods = {
        frame: startFrame,
      };

      for (let i = 0; i < frameCount; i++) {
        const img = new Image();
        img.src = currentFrame(i);
        images.push(img);
      }

      gsap.to(airpods, {
        frame: frameCount - 1,
        snap: 'frame',
        ease: 'none',
        duration: images.length / 30,
        scrollTrigger: {
          start: 'top top',
          end: '+=1000',
          scrub: 0.5,
        },
        onUpdate: render, // use animation onUpdate instead of scrollTrigger's onUpdate
      });

      images[0].onload = render;

      function render() {
        context.clearRect(0, 0, canvas.width, canvas.height);
        context.drawImage(images[airpods.frame], 0, 0);
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.roaster-hero {
  background-image: url('~@/assets/beyond-roast-bg.svg');
  background-repeat: no-repeat;
  background-size: 300%;
  background-position-y: center;
}

p {
  @apply text-xl;
  @apply text-zinc-400;
  @apply font-medium;
}

b {
  @apply text-green;
  @apply font-medium;
}

.product-image {
  @apply my-10;
}

section {
  @apply mb-14;
  @apply md:w-[60%];
  @apply mx-auto;
  @apply px-8;
}

body {
  @apply bg-light-gray;
}
</style>
