<template>
  <div class="my-4">
    <div class="flex text-green">
      <div class="font-medium mr-2">{{name}}:</div>
      <div>{{leadingUnit}}{{value}}{{unit}}</div>
    </div>
    <input
      type="range"
      :min="min"
      :max="max"
      :value="value"
      @input="$emit('update:value', $event.target.value)"
    />
  </div>
</template>

<script>
export default {
  name: 'SliderInput',
  components: {},
  props: {
    value: Number,
    min: Number,
    max: Number,
    name: String,
    unit: String,
    leadingUnit: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
input[type='range'] {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
  width: 100%;
}

/* Removes default focus */
input[type='range']:focus {
  outline: none;
}

/******** Chrome, Safari, Opera and Edge Chromium styles ********/
/* slider track */
input[type='range']::-webkit-slider-runnable-track {
  background-color: #e6e6e6;
  border-radius: 0.5rem;
  height: 0.5rem;
}

/* slider thumb */
input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  margin-top: -4px; /* Centers thumb on the track */
  background-color: #808080;
  border-radius: 0.5rem;
  height: 1rem;
  width: 1rem;
}

input[type='range']:focus::-webkit-slider-thumb {
  outline: 3px solid #808080;
  outline-offset: 0.125rem;
}
</style>
