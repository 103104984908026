<template>
  <div class="my-3">
    <p class="text-green ">
      {{title}}
    </p>
    <p>
      {{text}}
    </p>
  </div>
</template>

<script>
export default {
  name: 'KeyFeature',
  components: {},
  props: {
    title: String,
    text: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
