<template>
  <div class="flex justify-between">
    <div class="font-light text-zinc-400">
      {{name}}
    </div>
    <div class="text-green font-medium">
      {{leadingUnit}}{{value}}{{unit}}
    </div>
  </div>
</template>

<script>
export default {
  name: 'SummaryLine',
  components: {},
  props: {
    value: String,
    name: String,
    leadingUnit: String,
    unit: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
